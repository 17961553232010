/* .note is used in Semantic UI icons - so specifiy div.note to prevent interfering */
div.note:hover {
    box-shadow: 0 1px 3px 2px rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
    cursor: default;
}

div.note {
    width: 450px !important;
}

div.note.today {
    border: 3px solid #fbff00;
}

.delete-note {
    cursor: pointer;
    display: flex !important;
    float: right;
    padding: 3px;
}

/* workarond for Semantic UI inline loader not visible */
.ui.dimmer .ui.workaround.loader:before {
    border-color: rgba(0,0,0,.1);
}       
.ui.dimmer .ui.workaround.loader:after {
    border-color: #767676 transparent transparent;
}